@mixin visually-hidden {
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
}

@keyframes fade-flip-close {
  0% {
    opacity: 1;
    transform: rotate(-180deg) translateY(0) scaleY(1);
  }

  49% {
    opacity: 0;
    transform: rotate(-180deg) translateY(-5px) scaleY(0);
  }

  51% {
    opacity: 0;
    transform: rotate(0) translateY(-5px) scaleY(0);
  }

  100% {
    opacity: 1;
    transform: rotate(0) translateY(0) scaleY(1);
  }
}

@keyframes fade-flip-open {
  0% {
    opacity: 1;
    transform: rotate(0) translateY(0) scaleY(1);
  }

  49% {
    opacity: 0;
    transform: rotate(0) translateY(-5px) scaleY(0);
  }

  51% {
    opacity: 0;
    transform: rotate(-180deg) translateY(-5px) scaleY(0);
  }

  100% {
    opacity: 1;
    transform: rotate(-180deg) translateY(0) scaleY(1);
  }
}
