/* Commissioner */

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-Italic.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-ExtraLightItalic.woff2') format('woff2');
  font-weight: 200;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-SemiBold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-SemiBoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-ExtraBold.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Commissioner';
  src:
    url('~skins/glitch/hexst/fonts/Commissioner/Commissioner-ExtraBoldItalic.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

/* DM Mono */

@font-face {
  font-family: 'DM Mono';
  src:
    url('~skins/glitch/hexst/fonts/DMmono/DMmono-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DM Mono';
  src:
    url('~skins/glitch/hexst/fonts/DMmono/DMmono-Italic.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'DM Mono';
  src:
    url('~skins/glitch/hexst/fonts/DMmono/DMmono-Light.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DM Mono';
  src:
    url('~skins/glitch/hexst/fonts/DMmono/DMmono-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'DM Mono';
  src:
    url('~skins/glitch/hexst/fonts/DMmono/DMmono-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DM Mono';
  src:
    url('~skins/glitch/hexst/fonts/DMmono/DMmono-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
